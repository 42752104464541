<template lang="pug">
    el-dialog( :title="'Modificare timp - ' + Angajat.Nume + ' (#' + Angajat.Id + ')' "  :visible.sync="showPopup" class="my-dialog-class" )
        h3 Total ore lucrate '{{data}}': {{TotalOreLucrate|HHMM}}
        
        div(v-model="activeName")
            div( :title="'Ziua ' + zi.Zi " :name="idx" v-for="(zi,idx) in Zile" :key="'ziua-' + idx" style="padding-top: 0px; padding: 20px" :class="{ 'data-selectata' : zi.Zi == data }")
                br
                template( slot="title" )
                    div
                        span {{zi.Zi | nice_date}} 
                        span.error {{zi.Erori}}
                div 
                    table(:class="{ 'table' : true}" )
                        thead
                            tr
                                th
                                    el-tooltip( content="Adauga inregistrare pe primul rand" v-if="$has_right('modificare-timp-raport-totalizator')")
                                        el-button(type='primary' size='mini' round @click='adauga_rand_dedesubt(zi,-1)' ) +
                                th(colspan=5)
                                    strong Ziua {{zi.Zi}}
                            tr
                                th Adauga
                                th Data
                                th Ora
                                th Status
                                th Observatii
                                th Sterge
                        tbody
                            tr( v-for="(p,idx) in zi.Pontaje" )
                                td
                                    el-tooltip( content="Adauga inregistrare dedesubt" v-if="$has_right('modificare-timp-raport-totalizator')")
                                        el-button(type='primary' size='mini' round @click='adauga_rand_dedesubt(zi,idx)') +
                                td 
                                    el-date-picker( @change='change_row(zi, p)' v-model="p.Data" size='mini' type="date" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" :disabled="!$has_right('modificare-timp-raport-totalizator')")
                                td 
                                    el-time-select( @change='change_row(zi, p)' v-model="p.Ora" size='mini' :picker-options="{ start: '00:00', step: '00:01', end: '23:55'}" :disabled="!$has_right('modificare-timp-raport-totalizator')")
                                td 
                                    el-select( @change='change_row(zi, p)' v-model="p.Status" size='mini' :disabled="!$has_right('modificare-timp-raport-totalizator')")
                                        el-option( v-for="s in Statuses" :key="s" :label="s" :value="s" ) 
                                td 
                                    el-input( @change='change_row(zi, p)' v-model="p.Observatii" size='mini' :disabled="!$has_right('modificare-timp-raport-totalizator')")
                                td
                                    el-popconfirm( title="Esti sigur?" @confirm='sterge_rand(zi, p, idx)' confirm-button-text="Da" cancel-button-text='Nu' v-if="$has_right('modificare-timp-raport-totalizator')")
                                        el-button( slot="reference" size='mini') Sterge
                        
  
        

        span( slot="footer" class="dialog-footer" )
            el-button( style='float:left;min-width:150px;' @click="renunta") Renunta
            el-button( type="success" style='min-width:150px;' @click="save" :disabled="!$has_right('modificare-timp-raport-totalizator')") OK
</template>

<script>
import settings from "@/backend/LocalSettings";
import BaseComponent from '@/pages/BaseComponent';
import moment from 'moment';

export default {
  name: "modificare-timp-dialog",
  extends: BaseComponent,
  data () {
    return { 
      showPopup: false,
      activeName: 1,
      data: '',
      Statuses: ['intrare','iesire','intrare_pauza','iesire_pauza'],
      id_angajat:-1,
      Angajat: {},
      TotalOreLucrate: 0,
      Zile: [],

      toAdd: [],
      toDelete: [],
      toUpdate: [],
      datePickerOptions: {
        disabledDate: function (date) {
          var now = new Date();
          return date > now
        }
      }
    }
  },
  methods: {
     show_me: async function( id_angajat, data ) {
        this.showPopup          = true;
        this.data               = data;
        this.id_angajat         = id_angajat;
        this.toAdd              = [];
        this.toDelete           = [];
        this.toUpdate           = [];
        this.refresh_info();
    },
    refresh_info: async function(){
        var            result = await this.post("raport_totalizator2/ore_lucrate_angajat", { id_angajat: this.id_angajat, data: this.data } );
        this.Angajat          = result.Angajat;
        this.TotalOreLucrate  = result.TotalOreLucrate;
        this.Zile             = result.Zile;
        console.log({zile: this.Zile})
    },
    
    renunta(){
      this.showPopup = false;  
    },

    adauga_rand_dedesubt: function(zi, idx){
        var last_ora = '12:00';
        var status   = 'intrare';
        if( idx > 0 )
        {
            var last    = zi.Pontaje[ idx ];
            last_ora    = last.Ora;
            if( last.Status == 'intrare'          ) status = 'iesire';
            if( last.Status == 'intrare_pauza'    ) status = 'iesire_pauza';
        }

        var p = { Id:-1, Tag: this.Angajat.Tag, IdAngajat: this.Angajat.Id, Data: zi.Zi, Ora: last_ora, Status: status, ZiLucratoare: zi.Zi, Observatii: '' };
        zi.Pontaje.splice( idx + 1, 0, p);
        
        this.toAdd.push(p);
    },
    sterge_rand: function(zi, p, idx){
        zi.Pontaje.splice(idx, 1);
        if( p.Id != -1) this.toDelete.push(p);

        var   newArray  = this.toUpdate.filter(x=> x.Id != p.Id);
        this.toUpdate   = newArray;

        var   newArray  = this.toAdd.filter(x=> x != p);
        this.toAdd      = newArray;
    },
    change_row: function(zi, p){
        if( p.Id != -1 ) {
            var   newArray  = this.toUpdate.filter(x=>x.Id != p.Id);
            newArray.push(p);
            this.toUpdate = newArray;
        }
    },
    save: async function() {
        var minDate = moment();
        this.toDelete.forEach( p => { p.DataEveniment = `${p.Data} ${p.Ora}`; if( moment(p.DataEveniment ).isBefore(minDate) ) minDate = moment( p.DataEveniment ); });
        this.toAdd.forEach   ( p => { p.DataEveniment = `${p.Data} ${p.Ora}`; if( moment(p.DataEveniment ).isBefore(minDate) ) minDate = moment( p.DataEveniment );});
        this.toUpdate.forEach( p => { p.DataEveniment = `${p.Data} ${p.Ora}`; if( moment(p.DataEveniment ).isBefore(minDate) ) minDate = moment( p.DataEveniment );});
        
        await this.post("raport_totalizator2/salveaza_modificare_timp", { idAngajat: this.id_angajat, toDelete: this.toDelete, toUpdate: this.toUpdate, toAdd: this.toAdd, minDate: minDate.format("YYYY-MM-DD HH:mm:ss") } );
        this.showPopup = false;
        this.$emit("save");
    }
  }
};
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }
    .error {
        color: red;
        float: right;
        margin-left: 15px;
    }
    .table{
        width: 100%;
        th{
            text-align: left;
        }
    }
    .text-center{
        text-align: center;
    }
    .table th, .table td{
        padding: 2px 2px;
    }
    .data-selectata
    {
        background-color: #4bff4b;
        border-radius: 30px;
        td, th{
            padding-left: 10px;
        }
        
    }
    
</style>